import React from 'react';

import ContentPage from '../components/ContentPage';

const data = {
  title: 'Focusing on business value',
  version: 'v0.0 - content to be written',
  main: {
    paragraphs: [],
    relatedTopics: [],
  },
  furtherReading: [],
};

const Page = () => <ContentPage data={data} />;
export default Page;
